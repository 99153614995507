import logo from "./logo.png"

const  clientData = {
    client_entity: 13,
    attorney_firm: 'alliancebusinesslaw.com',
    attorney_name: 'Kimberly Simpson',
    attorney_number: '615-585-7720',
    attorney_email: 'ksimpson@alliancebusinesslaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_alliance_law_firm+(720p).mp4',
    mainColor: '#06365E',
    secondaryColor: '#B8860B',
    siteLink: 'https://www.alliancebusinesslaw.com/',
    logo
}

export default clientData